import { ReactElement } from 'react'
import { IconButton } from '@chakra-ui/react'

const SocialButton = ({
  icon,
  label,
  href,
}: {
  icon: ReactElement
  label: string
  href: string
}) => (
  <IconButton
    mx="1"
    bg="primaryGreen.300"
    aria-label={label}
    isRound
    as="a"
    href={href}
    title={`Visita il profilo ${label} di CarPlanner`}
    target="_blank"
    icon={icon}
    transition="background 0.3s ease"
    _hover={{
      bg: 'primaryGreen.800',
    }}
  />
)

export default SocialButton
