import { FC, SVGProps, CSSProperties } from 'react'
import { Box, StyleProps } from '@chakra-ui/react'
import styled from 'styled-components'

interface Props {
  icon: SVGProps<SVGSVGElement>
  name: string
  value: string
  style?: CSSProperties
}

const WithIconStyle = styled(Box)`
  font-family: Montserrat-Medium, sans-serif;
  font-size: 0.75rem;
  line-height: 15.6px;
  letter-spacing: 0.4px;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.palette.baseNeutral700};
  & svg {
    display: inline;
    vertical-align: bottom;
  }
`

const WithIcon: FC<Props & StyleProps> = ({ icon, name, value, ...other }) => (
  <WithIconStyle {...other}>
    {icon} {name} <strong>{value}</strong>
  </WithIconStyle>
)

export default WithIcon
