import React, { FC } from 'react'
import styled from 'styled-components'
import { List, ListItem, ListIcon, Box } from '@chakra-ui/react'
import { SuccessIcon } from 'src/icons'

interface Props {
  items: Array<string>
  color?: string
  fontSize?: string
  fontFamily?: string
  icon?: any
}

const StyledList = styled(ListItem)`
  & svg {
    display: inline;
  }
`

const ListWithIcons: FC<Props> = ({ items, color, fontSize, fontFamily, icon, ...props }) => (
  <Box paddingY="l">
    <List spacing={3}>
      {items.map(item => (
        <StyledList
          key={item}
          color={color || 'baseWhite'}
          fontFamily={fontFamily || 'Montserrat-SemiBold'}
          fontSize={fontSize || '1rem'}
          lineHeight="22.8px"
          letterSpacing="0.3px"
          textAlign="left"
          py="auto"
          {...props}
        >
          <ListIcon as={icon || SuccessIcon} fontSize="1rem" lineHeight="22.8px" />
          {` ${item}`}
        </StyledList>
      ))}
    </List>
  </Box>
)

export default ListWithIcons
