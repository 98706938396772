import React, { FC } from 'react'
import Image from 'next/image'
import styled from 'styled-components'
import { Box, Link, SimpleGrid, Text, useBreakpointValue } from '@chakra-ui/react'
import { PrimaryButton } from 'src/uikit'

interface LocalProps {
  style?: any
}

type Props = BoxGlobal.SliderBox & LocalProps

const BoxBackground = styled.div`
  transform: skew(-10deg);
  background-color: ${props => props.theme.palette.baseWhite};
  z-index: 2;
  border-radius: 10px;
  width: 80%;
  height: 9.438rem;
  margin: auto auto;

  @media screen and (min-width: 48em) {
    width: 30rem;
    height: 12.875rem;
  }
`

const LinkBoxWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 12.875rem;
  width: 100%;
  background-color: transparent;
  z-index: 20;

  @media screen and (min-width: 48em) {
    width: 31.5rem;
  }
`

const SliderBox: FC<Props> = ({ image, title, description, action, actionMobile, href, alt }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  return (
    <Box position="relative">
      <BoxBackground />
      <LinkBoxWrapper>
        <Text
          color="baseDark"
          fontFamily="Montserrat-SemiBold"
          fontSize={{ base: '1.125rem', md: '1.375rem' }}
          lineHeight={{ base: '1.463rem', md: '1.788rem' }}
          letterSpacing="0.6px"
          py="6"
          pl={{ base: '18%', md: '9' }}
          pr={{ base: '18%', md: '0' }}
          textAlign="left"
        >
          {title}
        </Text>
        <SimpleGrid
          gap="15px"
          w={{ base: '84%', md: '90%' }}
          h="90px"
          gridTemplateColumns={{ base: '1.0fr 0.9fr', md: '0.7fr 0.8fr' }}
          ml={{ base: '-7px', md: '-20px' }}
        >
          <Box ml={{ base: '18px', md: '-40px' }} position="relative">
            <Image src={image} layout="fill" objectFit="contain" placeholder="empty" alt={title} />
          </Box>
          <Box display={{ base: 'inline-block', md: 'flex' }}>
            <Box>
              <Text
                color="baseDark"
                fontFamily="Montserrat-Regular"
                fontSize="1.125rem"
                textAlign="left"
                lineHeight="23px"
                letterSpacing="0.3px"
                display={{ base: 'none', md: 'block' }}
              >
                {description}
              </Text>
              <Link href={href}>
                <PrimaryButton
                  title={alt}
                  action={isMobile && actionMobile ? actionMobile : action}
                  textTransform="uppercase"
                  marginTop={{ base: '-5px', md: '20px' }}
                />
              </Link>
            </Box>
          </Box>
        </SimpleGrid>
      </LinkBoxWrapper>
    </Box>
  )
}

export default SliderBox
