import React, { FC } from 'react'
import styled from 'styled-components'
import { List, ListItem, ListIcon, Box } from '@chakra-ui/react'
import { GreenCheckIcon } from 'src/icons'

const StyledList = styled(ListItem)`
  margin-top: unset !important;
  & svg {
    display: inline;
    margin-bottom: -5px;
    margin-top: 5px;
  }
`

const SimpleList: FC<OfferGlobal.OfferInsurances> = ({ insurances }) => (
  <Box paddingY="s">
    <List spacing={3}>
      {insurances.map(item => (
        <StyledList
          key={item}
          color="baseBlack"
          fontFamily="Montserrat-Medium"
          fontSize="0.75rem"
          letterSpacing="0.4px"
          textAlign="left"
          py="auto"
        >
          <ListIcon as={GreenCheckIcon} fontSize="0.75rem" marginTop=".4rem" />
          {` ${item}`}
        </StyledList>
      ))}
    </List>
  </Box>
)

export default SimpleList
