import React, { FC } from 'react'
import { Button, Text } from '@chakra-ui/react'

const RichButton: FC<ButtonsGlobal.RichButton> = ({
  title,
  description,
  onHoverMessage,
  onClick,
  disabled,
  selected
}) => (
  <Button
    title={onHoverMessage || ''}
    size="lg"
    bg={disabled ? 'baseNeutral.100' : 'baseWhite'}
    borderRadius={10}
    borderColor={selected ? 'primaryGreen.600' : '#d9dde1'}
    cursor={onClick ? 'pointer' : 'default'}
    variant="outline"
    width="full"
    height="56px"
    onClick={onClick}
    disabled={disabled || false}
  >
    <Text
      color="baseDark"
      fontFamily="Montserrat-SemiBold"
      fontSize="1.25rem"
      lineHeight="1.625rem"
      letterSpacing="0.57px"
    >
      {title}
    </Text>
    {description &&
      <Text
        color="baseDark"
        ml="xs"
        fontFamily="Montserrat-Medium"
        fontWeight="normal"
        fontSize="0.75rem"
        lineHeight="0.975rem"
        letterSpacing="0.4px"
      >
        {description}
      </Text>
    }
  </Button>
)

export default RichButton
