import React from 'react'
import styled from 'styled-components'
import { Box, Text } from '@chakra-ui/react'

const StyledCardButton = styled.div<{ children: any }>`
  display: inline;
  & svg {
    margin: auto;
  }
`

const CardButton = ({
  icon,
  title,
  description,
  onHoverMessage,
  onClick,
}) => (
  <StyledCardButton title={onClick ? onHoverMessage : ''}>
    <Box
      backgroundColor="baseWhite"
      borderRadius={10}
      width="100%"
      height="100%"
      minHeight="6.125rem"
      display="inline-block"
      mt="0.34rem"
      boxShadow="0 2px 4px 0px rgba(0, 0, 0, 0.08)"
      cursor={onClick ? 'pointer' : 'default'}
      padding="xs"
      textAlign="center"
      _hover={{
        textDecoration: 'none',
        boxShadow: '0 4px 12px 0px rgba(65, 164, 118, 0.22)',
      }}
      onClick={onClick}
    >
      {icon}
      <Text
        color="baseDark"
        fontFamily="Montserrat-Medium"
        fontSize="0.75rem"
        lineHeight="15.6px"
        letterSpacing="0.4px"
        marginTop="xs"
      >
        {title}
      </Text>
      <Text
        marginTop="xxs"
        color="baseDark"
        fontFamily="Montserrat-SemiBold"
        fontSize="0.875rem"
        lineHeight="18px"
        letterSpacing="0.2px"
      >
        {description}
      </Text>
    </Box>
  </StyledCardButton>
)

export default CardButton
