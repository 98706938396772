import React, { FC } from 'react'
import styled from 'styled-components'
import { Link, StyleProps } from '@chakra-ui/react'

const StylesLink = styled.span`
  & svg {
    display: inline;
  }
`

const TextLink: FC<LinkGlobal.Text & StyleProps> = ({ children, href, title, target, color, fontFamily, fontSize, style, ...props }) => (
  <StylesLink>
    <Link
      color={color || 'baseWhite'}
      title={title}
      target={target || '_self'}
      fontFamily={fontFamily ? fontFamily : 'Montserrat-Medium'}
      fontSize={fontSize ? fontSize : '1rem'}
      letterSpacing="0.3px"
      display="inline"
      _hover={{
        textDecoration: 'none',
      }}
      {...props}
      href={href}
    >
      {children}
    </Link>
  </StylesLink>
)

export default TextLink
