import React, { FC } from 'react'
import Image from 'next/image'
import { imageLoader } from 'src/utils/loader'

const CustomImage: FC<ImageGlobal.NextImage> = ({
  src,
  alt,
  className,
  layout,
  width,
  height,
  placeholder,
  lazy,
  decoding
}) => {
  if (layout !== 'fill' && width && height) {
    return (
      // @ts-ignore
      <Image
        loader={imageLoader}
        alt={alt}
        unoptimized
        src={src}
        className={className}
        layout={layout}
        width={width}
        height={height}
        placeholder={placeholder}
        loading={lazy ? 'lazy' : 'eager'}
        decoding={decoding}
      />
    )
  }

  return (
    // @ts-ignore
    <Image
      loader={imageLoader}
      alt={alt}
      unoptimized
      src={src}
      className={className}
      layout="fill"
      placeholder={placeholder}
      objectFit="contain"
      loading={lazy ? 'lazy' : 'eager'}
    />
  )
}

export default CustomImage
