import React, { FC } from 'react'
import { Link } from '@chakra-ui/react'

const SimpleLink: FC<LinkGlobal.Link> = ({ action, href, title, target }) => (
  <Link
    color="primaryGreen.800"
    title={title}
    target={target || '_self'}
    fontFamily="Montserrat-Medium, sans-serif"
    fontSize="1rem"
    letterSpacing="0.3px"
    lineHeight="22.8px"
    _hover={{
      textDecoration: 'none',
    }}
    href={href}
  >
    {action}
  </Link>
)

export default SimpleLink
