import React, { FC } from 'react'
import styled from 'styled-components'
import { Text, SimpleGrid } from '@chakra-ui/react'
import { Image } from 'src/uikit'

interface Props {
  items: Array<ListGlobal.WithDescription>
}

const ItemWithImage = styled.div`
  display: flex;
`
const Item = styled.span`
  max-width: 90%;
  display: inline;
  padding-left: 0.5rem;
`

const ListWithDescription: FC<Props> = ({ items }) => (
  <SimpleGrid columns={{ base: 1, md: 2 }} spacing="l" textAlign="left">
    {items.map(item => (
      <ItemWithImage key={item.title}>
        <Image src={item.icon} alt={item.title} layout="fixed" height="32px" width="32px" />
        <Item>
          <Text
            color="baseDark"
            fontFamily="Montserrat-SemiBold"
            fontSize="1.25rem"
            letterSpacing="0.57px"
            lineHeight="26px"
            whiteSpace="pre-wrap"
            textAlign="left"
          >
            {item.title}
          </Text>
          <Text
            marginTop="xs"
            color="baseDark"
            fontFamily="Montserrat-Medium"
            fontSize=".75rem"
            lineHeight="18px"
            letterSpacing="0.4px"
            whiteSpace="pre-wrap"
            textAlign="left"
          >
            {item.description}
          </Text>
        </Item>
      </ItemWithImage>
    ))}
  </SimpleGrid>
)

export default ListWithDescription
