import React, { FC, ReactNode, CSSProperties } from 'react'
import { Box, Badge, StyleProps } from '@chakra-ui/react'
import styled from 'styled-components'
import { ClocksIcon } from 'src/icons'

interface Props {
  children: ReactNode
}

const LimitedOfferStyle = styled(Box)`
  & svg {
    display: inline;
    margin-right: 4px;
    vertical-align: sub;
  }
`

const LimitedOffer: FC<Props & StyleProps> = ({ children, ...others }) => (
  <LimitedOfferStyle {...others}>
    <Badge
      bg="feedbackWarning"
      color="baseDark"
      fontFamily="Montserrat-SemiBold"
      fontSize="0.625rem"
      lineHeight="15.6px"
      letterSpacing=".33px"
      rounded="15px"
      paddingY=".3rem"
      paddingX=".5rem"
      textTransform="initial"
    >
      <ClocksIcon />
      {children}
    </Badge>
  </LimitedOfferStyle>
)

export default LimitedOffer
