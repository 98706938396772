import React, { FC, ReactNode } from 'react'
import { Badge } from '@chakra-ui/react'

interface Props {
  children: ReactNode
}

const GalleryMain: FC<Props> = ({ children }) => (
  <Badge
    bg="feedbackWarning"
    fontFamily="Montserrat-SemiBold"
    fontSize="0.875rem"
    textTransform="none"
    color="baseDark"
    lineHeight="1.125rem"
    letterSpacing=".2px"
    rounded="14.5px"
    paddingY=".4rem"
    paddingX="1rem"
    position="absolute"
    top="1rem"
    left="1rem"
  >
    {children}
  </Badge>
)

export default GalleryMain
