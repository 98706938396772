import React, { FC } from 'react'
import styled from 'styled-components'
import { TextStyles } from 'src/styles/styled-components/Texts'

const StyledLink = styled.a`
  background-color: ${({ theme }) => theme.palette.baseWhite};
  color: ${({ theme }) => theme.palette.primaryGreen900};
  border-radius: 4px;
  border: 1px solid rgb(46, 197, 141);
  text-transform: uppercase;
  ${TextStyles.interactiveButtonsSG900SemiboldC};
  padding: 0.94rem ${({ theme }) => theme.spaces.spacing_xl} 0.88rem
    ${({ theme }) => theme.spaces.spacing_xl};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primaryGreen900};
    border-color: ${({ theme }) => theme.palette.primaryGreen900};
    ${TextStyles.interactiveButtonsSWhiteSemiboldC};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.primaryGreen800};
    border-color: ${({ theme }) => theme.palette.primaryGreen800};
    ${TextStyles.interactiveButtonsSWhiteSemiboldC};
  }
`

const InvertedButtonLink: FC<ButtonsGlobal.LinkButton> = ({ action, href, title, target }) => (
  <StyledLink target={target || '_self'} href={href} title={title || action}>
    {action}
  </StyledLink>
)

export default InvertedButtonLink
