import React, { FC } from 'react'
import styled from 'styled-components'
import { Box, StyleProps } from '@chakra-ui/react'
import { TextStyles } from 'src/styles/styled-components/Texts'

interface Props {
  value: string
  description?: string
}

const BoxDescription = styled.span`
  display: block;
  margin-top: 0;
  ${TextStyles.infoXxsN700MediumDx}
`

const PriceBox: FC<Props & StyleProps> = ({ value, description, ...others }) => (
  <Box
    color="baseDark"
    fontFamily="Montserrat-SemiBold"
    fontSize="1.5rem"
    lineHeight="31.2px"
    letterSpacing="0.86px"
    {...others}
  >
    {value}
    {description && <BoxDescription>{description}</BoxDescription>}
  </Box>
)

export default PriceBox
