import { forwardRef, FC } from 'react'
import { Button, StyleProps, useBreakpointValue } from '@chakra-ui/react'

const SecondaryButton: FC<ButtonsGlobal.Button & StyleProps> = forwardRef(({ title, width, action, disabled, onClick, ...others }, ref) => {
  const buttonSize = useBreakpointValue(['md', 'lg'])

  return <Button
    variant="outline"
    bg="baseWhite"
    color="primaryGreen.900"
    fontFamily="Montserrat-SemiBold"
    borderRadius={4}
    display="block"
    overflow="hidden"
    title={title}
    disabled={disabled}
    width={width || '100%'}
    size={buttonSize}
    borderColor="primaryGreen.300"
    px={{ base: '0.75rem', md: '1rem' }}
    _active={{
      bg: 'primaryGreen.800',
      borderColor: 'primaryGreen.800',
      color: 'baseWhite',
    }}
    _hover={{
      bg: disabled ? '' : 'primaryGreen.900',
      borderColor: disabled ? '' : 'primaryGreen.900',
      color: disabled ? '' : 'baseWhite',
    }}
    _disabled={{
      bg: 'baseNeutral.200',
      color: 'baseNeutral.400',
      cursor: 'default'
    }}
    onClick={onClick}
    {...others}
  >
    {action}
  </Button>
})

export default SecondaryButton
