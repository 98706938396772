import React, { FC } from 'react'
import styled from 'styled-components'
import { Button } from '@chakra-ui/react'

const ButtonWithIcon: FC<ButtonsGlobal.ButtonWithIcon & { children: any }> = ({ children, title, icon, iconPosition, asLink, onClick }) => {
  return asLink ?
    (
      <Button title={title} onClick={onClick} variant="link">
        {iconPosition !== "right" ? icon : null}
        {children}
        {iconPosition === "right" ? icon : null}
      </Button>
    )
    :
    (
      <Button title={title} onClick={onClick}>
        {iconPosition !== "right" ? icon : null}
        {children}
        {iconPosition === "right" ? icon : null}
      </Button>
    )
}

export default ButtonWithIcon
