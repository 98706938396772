import React, { FC, ReactNode } from 'react'
import { Text } from '@chakra-ui/react'
import { HTMLChakraProps, ThemingProps } from '@chakra-ui/system'

interface Props extends HTMLChakraProps<'p'>, ThemingProps<'Text'> {
  children: ReactNode
  color?: string
}

const H2: FC<Props> = ({ children, color, ...props }) => (
  <Text
    as="h2"
    fontFamily="Montserrat-SemiBold"
    fontSize="1.5rem"
    lineHeight="36px"
    textAlign="left"
    color={color || 'baseWhite'}
    whiteSpace="pre-wrap"
    {...props}
  >
    {children}
  </Text>
)

export default H2
