import React, { FC, ReactNode } from 'react'
import { StyleProps, Text } from '@chakra-ui/react'

interface Props {
  children: ReactNode
}

const H3: FC<Props & StyleProps> = ({ children, ...others }) => (
  <Text
    as="h3"
    marginBottom="xxl4"
    fontFamily="Montserrat-SemiBold"
    fontSize="1.75rem"
    lineHeight="2.275rem"
    {...others}
    color="baseDark"
  >
    {children}
  </Text>
)

export default H3
