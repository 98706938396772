import React, { FC } from 'react'
import { Box } from '@chakra-ui/react'
import { Image } from 'src/uikit'

const ImageBox: FC<ImageGlobal.Image> = ({ src, alt }) => (
  <Box borderRadius={10} pos="relative" overflow="hidden" w="full" h="10rem">
    <Image src={src} alt={alt} layout="fill" className="imageGalleryGradient" />
  </Box>
)

export default ImageBox
