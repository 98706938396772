/**
 * @deprecated
 */
import { css } from 'styled-components'

export const TextStyles = {
  headingMontserratH1WhiteSemiboldSx: css`
    font-size: 28px;
    line-height: 1.29;
    letter-spacing: 0.86px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH1WhiteSemiboldDx: css`
    font-size: 28px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.52px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH1WhiteSemiboldC: css`
    font-size: 28px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.86px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH1G900SemiboldSx: css`
    font-size: 28px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.86px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH1DarkSemiboldSx: css`
    font-size: 1.75rem;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.86px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat-SemiBold, sans-serif;
  `,

  headingMontserratH1DarkSemiboldDx: css`
    font-size: 28px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.86px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH1DarkSemiboldC: css`
    font-size: 1.75rem;
    line-height: 36.4px;
    text-align: center;
    letter-spacing: 0.86px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat-SemiBold, sans-serif;
  `,

  headingMontserratH2WhiteSemiboldSx: css`
    font-size: 1.5rem;
    line-height: 31px;
    text-align: inherit;
    letter-spacing: 0.68px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat-SemiBold, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH2WhiteSemiboldDx: css`
    font-size: 24px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.68px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH2WhiteSemiboldC: css`
    font-size: 24px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.68px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH2DarkSemiboldSx: css`
    font-size: 24px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.68px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH2DarkSemiboldDx: css`
    font-size: 24px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.68px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  headingMontserratH2DarkSemiboldC: css`
    font-size: 24px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.68px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH3WhiteSemiboldSx: css`
    font-size: 22px;
    line-height: 1.27;
    text-align: inherit;
    letter-spacing: 0.6px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH3WhiteSemiboldDx: css`
    font-size: 22px;
    line-height: 1.27;
    text-align: right;
    letter-spacing: 0.6px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH3WhiteSemiboldC: css`
    font-size: 22px;
    line-height: 1.27;
    text-align: center;
    letter-spacing: 0.6px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH3DarkSemiboldSx: css`
    font-size: 1.375rem;
    line-height: 28.6px;
    text-align: inherit;
    letter-spacing: 0.6px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat-SemiBold, sans-serif;
  `,

  headingMontserratH3DarkSemiboldDx: css`
    font-size: 22px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.6px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH3DarkSemiboldC: css`
    font-size: 1.375rem;
    line-height: 28.6px;
    text-align: center;
    letter-spacing: 0.6px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat-SemiBold, sans-serif;
    font-weight: 400;
  `,

  headingMontserratH4WhiteSemiboldSx: css`
    font-size: 20px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.58px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH4WhiteSemiboldDx: css`
    font-size: 20px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.58px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH4WhiteSemiboldC: css`
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.58px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH4DarkSemiboldSx: css`
    font-size: 20px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.58px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH4DarkSemiboldDx: css`
    font-size: 20px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.58px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH4DarkSemiboldC: css`
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.58px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodyLWhiteSemiboldSx: css`
    font-size: 20px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.57px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodyLWhiteSemiboldDx: css`
    font-size: 20px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.57px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodyLWhiteSemiboldC: css`
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.57px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodyLN700SemiboldSx: css`
    font-size: 20px;
    line-height: 1.2;
    text-align: inherit;
    letter-spacing: 0.57px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodyLN700SemiboldDx: css`
    font-size: 20px;
    line-height: 1.2;
    text-align: right;
    letter-spacing: 0.17px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodyLN700SemiboldC: css`
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 0.57px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodyLDarkSemiboldSx: css`
    font-size: 1.25rem;
    line-height: 26px;
    text-align: inherit;
    letter-spacing: 0.57px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat-SemiBold, sans-serif;
  `,

  bodyLDarkSemiboldDx: css`
    font-size: 20px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.57px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyLDarkSemiboldC: css`
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.57px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH5WhiteSemiboldSx: css`
    font-size: 18px;
    line-height: 1.28;
    text-align: inherit;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH5WhiteSemiboldDx: css`
    font-size: 18px;
    line-height: 1.28;
    text-align: right;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH5WhiteSemiboldC: css`
    font-size: 18px;
    line-height: 1.28;
    text-align: center;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH5G900SemiboldSx: css`
    font-size: 18px;
    line-height: 1.28;
    text-align: inherit;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH5DarkSemiboldSx: css`
    font-size: 18px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH5DarkSemiboldDx: css`
    font-size: 18px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  headingMontserratH5DarkSemiboldC: css`
    font-size: 18px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodyMDarkSemiboldSx: css`
    font-size: 18px;
    line-height: 1.28;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodyMDarkSemiboldDx: css`
    font-size: 18px;
    line-height: 1.28;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodyMDarkSemiboldC: css`
    font-size: 18px;
    line-height: 1.28;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodyMN700500Sx: css`
    font-size: 18px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 500;
  `,

  bodyMN700500Dx: css`
    font-size: 18px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 500;
  `,

  bodyMN700500C: css`
    font-size: 18px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 500;
  `,

  bodyMN700RegularSx: css`
    font-size: 18px;
    line-height: 1.28;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 400;
  `,

  bodyMN700RegularDx: css`
    font-size: 18px;
    line-height: 1.28;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 400;
  `,

  bodyMN700RegularC: css`
    font-size: 18px;
    line-height: 1.28;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 400;
  `,

  interactiveButtonsSWhiteSemiboldSx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSWhiteSemiboldDx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSWhiteSemiboldC: css`
    font-size: 1rem;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSN700SemiboldSx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSN700SemiboldDx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSN700SemiboldC: css`
    font-size: 16px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSN500SemiboldSx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSN500SemiboldDx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSN500SemiboldC: css`
    font-size: 16px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSN400SemiboldSx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral400};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSN400SemiboldDx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral400};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSN400SemiboldC: css`
    font-size: 16px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral400};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSG900SemiboldSx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSG900SemiboldDx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSG900SemiboldC: css`
    font-size: 16px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSG800SemiboldSx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen800};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSG800SemiboldDx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen800};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSG800SemiboldC: css`
    font-size: 16px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen800};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSG600SemiboldSx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSG600SemiboldDx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSG600SemiboldC: css`
    font-size: 16px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSBlackSemiboldSx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSBlackSemiboldDx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveButtonsSBlackSemiboldC: css`
    font-size: 16px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySWhiteSemiboldSx: css`
    font-size: 16px;
    line-height: 1.43;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySWhiteSemiboldDx: css`
    font-size: 16px;
    line-height: 1.43;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySWhiteSemiboldC: css`
    font-size: 16px;
    line-height: 1.43;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySN700SemiboldSx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySN700SemiboldDx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySN700SemiboldC: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySN500SemiboldSx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySN500SemiboldDx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySN500SemiboldC: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySG700SemiboldSx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySG700SemiboldDx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySG700SemiboldC: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySG600SemiboldSx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySG600SemiboldDx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySG600SemiboldC: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySDarkSemiboldSx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySDarkSemiboldDx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  bodySDarkSemiboldC: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  `,

  interactiveLinksSDark500Sx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat-Medium, sans-serif;
    font-weight: 500;
  `,

  interactiveLinksSDark500Dx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 500;
  `,

  interactiveLinksSDark500C: css`
    font-size: 16px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
    font-weight: 500;
  `,

  interactiveButtonsSWhite500Sx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 500;
  `,

  interactiveButtonsSWhite500Dx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsSWhite500C: css`
    font-size: 16px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
    font-weight: 500;
  `,

  interactiveButtonsSN400500Sx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral400};
    font-family: Montserrat, sans-serif;
    font-weight: 500;
  `,

  interactiveButtonsSN400500Dx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral400};
    font-family: Montserrat, sans-serif;
    font-weight: 500;
  `,

  interactiveButtonsSN400500C: css`
    font-size: 16px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseNeutral400};
    font-family: Montserrat, sans-serif;
    font-weight: 500;
  `,

  interactiveButtonsSG800500Sx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen800};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsSG800500Dx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen800};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsSG800500C: css`
    font-size: 16px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen800};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsSG600500Sx: css`
    font-size: 16px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsSG600500Dx: css`
    font-size: 16px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsSG600500C: css`
    font-size: 16px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodySWhite500Sx: css`
    font-size: 16px;
    line-height: 1.43;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodySWhite500Dx: css`
    font-size: 16px;
    line-height: 1.43;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodySWhite500C: css`
    font-size: 16px;
    line-height: 1.43;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodySN700500Sx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodySN700500Dx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodySN700500C: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodySG900500Sx: css`
    font-size: 16px;
    line-height: 1.43;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  bodySG900500Dx: css`
    font-size: 16px;
    line-height: 1.43;
    text-align: right;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  bodySG900500C: css`
    font-size: 16px;
    line-height: 1.43;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  bodySG700500StrikeSx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: #646b73;
    font-family: Montserrat, sans-serif;
  `,

  bodySG700500Sx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodySG700500Dx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodySG700500C: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodySDark500Sx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodySDark500Dx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.3px;
    color: #373737;
    font-family: Montserrat, sans-serif;
  `,

  bodySDark500C: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.3px;
    color: #303131;
    font-family: Montserrat, sans-serif;
  `,

  bodySN700RegularSx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodySN700RegularDx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodySN700RegularC: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodySN500RegularSx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
  `,

  bodySN500RegularDx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
  `,

  bodySN500RegularC: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
  `,

  bodySG600RegularSx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodySG600RegularDx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodySG600RegularC: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodySDarkRegularSx: css`
    font-size: 1rem;
    line-height: 20.8px;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat-Regular, sans-serif;
  `,

  bodySDarkRegularDx: css`
    font-size: 16px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodySDarkRegularC: css`
    font-size: 1rem;
    line-height: 20.8px;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat-Regular, sans-serif;
  `,

  interactiveMenuDarkSemiboldSx: css`
    font-size: 14px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.25px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat-SemiBold, sans-serif;
  `,

  interactiveLinksXsWhiteSemiboldSx: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  interactiveLinksXsWhiteSemiboldDx: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  interactiveLinksXsWhiteSemiboldC: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  interactiveLinksXsG600SemiboldSx: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  interactiveLinksXsG600SemiboldDx: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  interactiveLinksXsG600SemiboldC: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.1px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsXsG900SemiboldSx: css`
    font-size: 14px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.26px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsXsG900SemiboldDx: css`
    font-size: 14px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.26px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsXsG900SemiboldC: css`
    font-size: 14px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.26px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  infoStrikeN700SemiboldSx: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoStrikeN700SemiboldDx: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoStrikeN700SemiboldC: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsWhiteSemiboldSx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsWhiteSemiboldDx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsWhiteSemiboldC: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN700SemiboldSx: css`
    font-size: 14px;
    line-height: 1.57;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN700SemiboldDx: css`
    font-size: 14px;
    line-height: 1.57;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN700SemiboldC: css`
    font-size: 14px;
    line-height: 1.57;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN500SemiboldSx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN500SemiboldDx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN500SemiboldC: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG600SemiboldSx: css`
    font-size: 14px;
    line-height: 1.57;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG600SemiboldDx: css`
    font-size: 14px;
    line-height: 1.57;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG600SemiboldC: css`
    font-size: 14px;
    line-height: 1.57;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsDarkSemiboldSx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsDarkSemiboldDx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsDarkSemiboldC: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsXsDark500Sx: css`
    font-size: 14px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.26px;
    color: #303131;
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsXsDark500Dx: css`
    font-size: 14px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.26px;
    color: #303131;
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsXsDark500C: css`
    font-size: 14px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.26px;
    color: #303131;
    font-family: Montserrat, sans-serif;
  `,

  infoSN700500Sx: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoSN700500Dx: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoSN700500C: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoSDark500Sx: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  infoSDark500Dx: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  infoSDark500C: css`
    font-size: 14px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN700500Sx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN700500Dx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN700500C: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN500500Sx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN500500Dx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN500500C: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG900500Sx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG900500Dx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG900500C: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG700500Sx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG700500Dx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG700500C: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG600500Sx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG600500Dx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG600500C: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsDark500Sx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsDark500Dx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsDark500C: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  feedbackWarningRegularSx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.feedbackWarning};
    font-family: Montserrat, sans-serif;
  `,

  feedbackWarningRegularDx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.feedbackWarning};
    font-family: Montserrat, sans-serif;
  `,

  feedbackWarningRegularC: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.feedbackWarning};
    font-family: Montserrat, sans-serif;
  `,

  feedbackSuccessRegularSx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.feedbackSuccess};
    font-family: Montserrat, sans-serif;
  `,

  feedbackSuccessRegularDx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.feedbackSuccess};
    font-family: Montserrat, sans-serif;
  `,

  feedbackSuccessRegularC: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.feedbackSuccess};
    font-family: Montserrat, sans-serif;
  `,

  feedbackErrorRegularSx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.feedbackError};
    font-family: Montserrat, sans-serif;
  `,

  feedbackErrorRegularDx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.feedbackError};
    font-family: Montserrat, sans-serif;
  `,

  feedbackErrorRegularC: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.feedbackError};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN700RegularSx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN700RegularDx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN700RegularC: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN500RegularSx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN500RegularDx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsN500RegularC: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseNeutral500};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG600RegularSx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG600RegularDx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsG600RegularC: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.primaryGreen600};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsDarkRegularSx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: inherit;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsDarkRegularDx: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: right;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXsDarkRegularC: css`
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsXxsN400SemiboldSx: css`
    font-size: 12px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.23px;
    color: ${({ theme }) => theme.palette.baseNeutral400};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsXxsN400SemiboldDx: css`
    font-size: 12px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.23px;
    color: ${({ theme }) => theme.palette.baseNeutral400};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsXxsN400SemiboldC: css`
    font-size: 12px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.23px;
    color: ${({ theme }) => theme.palette.baseNeutral400};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsXxsG900SemiboldSx: css`
    font-size: 12px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.23px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsXxsG900SemiboldDx: css`
    font-size: 12px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.23px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  interactiveButtonsXxsG900SemiboldC: css`
    font-size: 12px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.23px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  infoXsWhiteSemiboldSx: css`
    font-size: 12px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  infoXsWhiteSemiboldDx: css`
    font-size: 12px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  infoXsWhiteSemiboldC: css`
    font-size: 12px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  infoXsN700SemiboldSx: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoXsN700SemiboldDx: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoXsN700SemiboldC: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoXsDarkSemiboldSx2: css`
    font-size: 12px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  infoXsDarkSemiboldSx: css`
    font-size: 12px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  infoXsDarkSemiboldDx: css`
    font-size: 12px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  infoXsDarkSemiboldC: css`
    font-size: 12px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsWhiteSemiboldSx: css`
    font-size: 12px;
    line-height: 1.5;
    text-align: inherit;
    letter-spacing: 0.17px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsWhiteSemiboldDx: css`
    font-size: 12px;
    line-height: 1.5;
    text-align: right;
    letter-spacing: 0.17px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsWhiteSemiboldC: css`
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.17px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsDarkSemiboldSx: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsDarkSemiboldDx: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsDarkSemiboldC: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  infoXsWhite500Sx: css`
    font-size: 12px;
    line-height: 1.25;
    text-align: inherit;
    letter-spacing: 0.34px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  infoXsWhite500Dx: css`
    font-size: 12px;
    line-height: 1.25;
    text-align: right;
    letter-spacing: 0.34px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  infoXsWhite500C: css`
    font-size: 12px;
    line-height: 1.25;
    text-align: center;
    letter-spacing: 0.34px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  infoXsN700500Sx: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoXsN700500Dx: css`
    font-size: 12px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoXsN700500C: css`
    font-size: 12px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoXsDark500Sx: css`
    font-size: 12px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  infoXsDark500Dx: css`
    font-size: 12px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  infoXsDark500C: css`
    font-size: 12px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsWhite500Sx: css`
    font-size: 12px;
    line-height: 1.5;
    text-align: inherit;
    letter-spacing: 0.17px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsWhite500Dx: css`
    font-size: 12px;
    line-height: 1.5;
    text-align: right;
    letter-spacing: 0.17px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsWhite500C: css`
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.17px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsG900500Sx: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.17px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsG900500Dx: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.17px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsG900500C: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.17px;
    color: ${({ theme }) => theme.palette.primaryGreen900};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsDark500Sx: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: inherit;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsDark500Dx: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: right;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxsDark500C: css`
    font-size: 12px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  feedbackXsErrorRegularC: css`
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.17px;
    color: ${({ theme }) => theme.palette.feedbackError};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxxsWhiteSemiboldSx: css`
    font-size: 10px;
    line-height: 1.56;
    text-align: inherit;
    letter-spacing: 0.33px;
    color: ${({ theme }) => theme.palette.baseWhite};
    font-family: Montserrat, sans-serif;
  `,

  bodyXxxsDarkSemiboldSx: css`
    font-size: 10px;
    line-height: 1.56;
    text-align: inherit;
    letter-spacing: 0.33px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  infoXxsN700500Sx: css`
    font-size: 10px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.33px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoXxsN700500Dx: css`
    font-size: 10px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.33px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoXxsN700500C: css`
    font-size: 10px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.33px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  infoXxsDark500Sx: css`
    font-size: 10px;
    line-height: normal;
    text-align: inherit;
    letter-spacing: 0.33px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  infoXxsDark500Dx: css`
    font-size: 10px;
    line-height: normal;
    text-align: right;
    letter-spacing: 0.33px;
    color: #2b2b2b;
    font-family: Montserrat, sans-serif;
  `,

  infoXxsDark500C: css`
    font-size: 10px;
    line-height: normal;
    text-align: center;
    letter-spacing: 0.33px;
    color: #2b2b2b;
    font-family: Montserrat, sans-serif;
  `,

  bodyXsDarkMediumSx: css`
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat, sans-serif;
  `,

  infoXxsN700MediumC: css`
    font-size: 10px;
    text-align: center;
    letter-spacing: 0.33px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat, sans-serif;
  `,

  bodySDarkMediumSx: css`
    font-size: 1rem;
    line-height: 20.8px;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.baseDark};
    font-family: Montserrat-Medium, sans-serif;
  `,

  interactiveButtonsSG800MediumSx: css`
    font-size: 1rem;
    line-height: 22.8px;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.palette.primaryGreen800};
    font-family: Montserrat-Medium, sans-serif;
  `,

  infoXxsN700MediumDx: css`
    font-size: 0.75rem;
    line-height: 10px;
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.palette.baseNeutral700};
    font-family: Montserrat-Medium, sans-serif;
  `,
}
