import React, { FC } from 'react'
import { Box, Text } from '@chakra-ui/react'
import Image from 'next/image'

interface LocalPros {
  marginX?: string | number
  marginY?: string | number
  marginTop?: string | number
  marginBottom?: string | number
}

type Props = LocalPros & ImageGlobal.ImageButton

const ImageButton: FC<Props> = ({
  src,
  alt,
  action,
  onClick,
  marginX,
  marginY,
  marginBottom,
  marginTop,
}) => (
  <Box
    borderRadius={10}
    pos="relative"
    overflow="hidden"
    w="full"
    h="11.35rem"
    bg="baseWhite"
    cursor="pointer"
    onClick={onClick}
    my={marginY}
    mx={marginX}
    mt={marginTop}
    mb={marginBottom}
  >
    <Image src={src} alt={alt} layout="fill" loading="lazy" objectFit="contain" />
    {action && (
      <Box
        position="absolute"
        height="100%"
        width="100%"
        backgroundColor="rgba(0, 0, 0, 0.6)"
        zIndex="2"
      >
        <Text
          color="baseWhite"
          fontFamily="Montserrat-SemiBold"
          fontSize="1.25rem"
          lineHeight="26px"
          letterSpacing="0.58px"
          position="relative"
          top="45%"
        >
          {action}
        </Text>
      </Box>
    )}
  </Box>
)

export default ImageButton
