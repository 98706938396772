import React, { FC, CSSProperties } from 'react'
import { Badge, Text } from '@chakra-ui/react'

interface Props {
  day: number
  month: string
  style?: CSSProperties
}

const DateBox: FC<Props> = ({ day, month, style }) => (
  <Badge p={2} bg="primaryGreen.300" color="primaryGreen.900" borderRadius="4" position="absolute" top="1.5rem" left="1.5rem">
    <Text
      color="primaryGreen.900"
      fontFamily="Montserrat-SemiBold"
      fontSize="1.25rem"
      lineHeight="26px"
      letterSpacing="0.57px"
    >
      {day}
    </Text>
    <Text
      color="primaryGreen.900"
      fontFamily="Montserrat-SemiBold"
      fontSize="0.875rem"
      lineHeight="18px"
      letterSpacing="0.2px"
    >
      {month}
    </Text>
  </Badge>
)

export default DateBox
