import React, { FC } from 'react'
import { Box, LinkBox } from '@chakra-ui/react'
import { theme } from 'src/themes'

const CardLink: FC<LinkGlobal.Card & { children: any }> = ({ href, onClick, title, bg, children }) =>
  href ?
    <LinkBox
      title={title}
      borderRadius={10}
      bg={bg || 'baseWhite'}
      pb="l"
      overflow="hidden"
      borderColor={theme.colors.borders.grey}
      borderWidth={1}
      boxShadow={theme.shadows.cards}
      _hover={{
        textDecoration: 'none',
        boxShadow: '0 4px 12px 0px rgba(65, 164, 118, 0.22)',
      }}>
      {children}
    </LinkBox>
    :
    <Box title={title} onClick={onClick} cursor="pointer">
      {children}
    </Box>


export default CardLink
