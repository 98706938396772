import React, { FC, ReactNode, CSSProperties } from 'react'
import { Badge, StyleProps } from '@chakra-ui/react'

interface Props {
  children: ReactNode
}

const Info: FC<Props & StyleProps> = ({ children, ...others }) => (
  <Badge
    bg="baseNeutral.700"
    fontFamily="Montserrat-SemiBold"
    fontSize="0.625rem"
    color="baseWhite"
    lineHeight="15px"
    letterSpacing=".33px"
    rounded="15px"
    paddingY=".3rem"
    paddingX=".5rem"
    {...others}
  >
    {children}
  </Badge>
)

export default Info
