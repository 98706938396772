import React, { FC } from 'react'
import { SimpleLink } from 'src/uikit'
import styled from 'styled-components'
import { H3Title } from 'src/uikit'
import { TextStyles } from 'src/styles/styled-components/Texts'

interface Props {
  title: string
  action: string
  alt: string
  href: string
}

const Action = styled.span`
  float: right;
`

const SectionTitle: FC<Props> = ({ title, action, alt, href }) => (
  <>
    <H3Title textAlign="left">
      {title}
      <Action>
        <SimpleLink action={action} href={href} title={alt} />
      </Action>
    </H3Title>
  </>
)

export default SectionTitle
