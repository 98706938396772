import React, { FC } from 'react'
import FsLightbox from 'fslightbox-react'

interface Props {
  images: Array<ImageGlobal.Image>
  slide?: number
  status: boolean
}

const LightBox: FC<Props> = ({ slide, status, images }) => {
  const imagesSrc = images.map(image => image.src)
  const customAttributes = images.map(image => ({ alt: image.alt }))
  return (
    <FsLightbox
      toggler={status}
      type="image"
      sources={imagesSrc}
      customAttributes={customAttributes}
      thumbs={imagesSrc}
      slide={slide || 1}
    />
  )
}

export default LightBox
