import React, { FC } from 'react'
import styled from 'styled-components'
import { SimpleLink, H3Title } from 'src/uikit'
import { TextStyles } from 'src/styles/styled-components/Texts'

import { ArrowRightIcon } from 'src/icons'
import { useBreakpointValue } from '@chakra-ui/react'

interface Props {
  title: string
  action?: string
  alt?: string
  href?: string
  target?: string
  icon?: SVGElement
}

const Action = styled.div<{ children: any }>`
  text-align: center;
  & svg {
    display: inline;
    margin-bottom: -3px;
  }
  & a {
    ${TextStyles.interactiveLinksSDark500Sx}
  }
`

const ActionFloat = styled.span<{ children: any }>`
  float: right;
  & svg {
    display: inline;
    margin-bottom: -3px;
  }
  & a {
    ${TextStyles.interactiveLinksSDark500Sx}
  }
`

const SectionTitle: FC<Props> = ({ title, action, alt, href, target, icon }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })

  return <H3Title textAlign={isMobile ? 'center' : 'left'} marginBottom="1.8rem">
    {title}
    {href && action ?
      <>
        {isMobile ?
          <Action>
            <SimpleLink action={action} href={href} title={alt} target={target || '_blank'} />
            {icon || <ArrowRightIcon />}
          </Action>
          :
          <ActionFloat>
            <SimpleLink action={action} href={href} title={alt} target={target || '_blank'} />
            {icon || <ArrowRightIcon />}
          </ActionFloat>
        }
      </>
      : null
    }
  </H3Title>
}

export default SectionTitle
