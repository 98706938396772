import React, { FC } from 'react'
import { Box, Slide, StyleProps } from '@chakra-ui/react'

interface Props {
  visible?: boolean
  height?: number | string
  h?: number | string
  children
}

const Sticky: FC<Props & StyleProps> = ({ visible, children, height, h, ...other }) => (
  <Slide
    direction="bottom"
    style={{ zIndex: 2 }}
    in={visible}
  >
    <Box
      bg="baseWhite"
      boxShadow="0 -2px 10px 0px rgba(0, 0, 0, 0.08)"
      h={height || h}
      {...other}
    >
      {children}
    </Box>
  </Slide>
)

export default Sticky
