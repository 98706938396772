import React, { FC } from 'react'
import { Divider, Text } from '@chakra-ui/react'
import { theme } from 'src/themes'

interface Props {
  title: string
  subtitle: string
  href?: string
}

const OfferCard: FC<Props> = ({ title, subtitle, href }) => (
  <>
    <Text
      color="baseDark"
      fontFamily="Montserrat-SemiBold"
      fontSize="1rem"
      lineHeight="21px"
      letterSpacing="0.2px"
    >
      {href ? <a href={href}>{title}</a> : title}
    </Text>
    <Text
      color="baseNeutral.700"
      fontFamily="Montserrat-Regular"
      fontSize="0.875rem"
      textTransform="capitalize"
      lineHeight="18px"
      letterSpacing="0.2px"
      marginTop="0rem!important"
      mb="xs"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {href ? <a href={href}>{subtitle}</a> : subtitle}
    </Text>
    <Divider />
  </>
)

export default OfferCard
