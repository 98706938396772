import React, { FC } from 'react'
import styled from 'styled-components'
import { TextStyles } from 'src/styles/styled-components/Texts'

const StyledLink = styled.a`
  background-color: ${({ theme }) => theme.palette.primaryGreen300};
  border-radius: 4px;
  ${TextStyles.interactiveButtonsSG900SemiboldC};
  padding: 0.94rem 2.36rem 0.88rem 2.36rem;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primaryGreen900};
    ${TextStyles.interactiveButtonsSWhiteSemiboldC};
  }
  &:active {
    background-color: ${({ theme }) => theme.palette.primaryGreen800};
    ${TextStyles.interactiveButtonsSWhiteSemiboldC};
  }
`

const LinkButton: FC<ButtonsGlobal.LinkButton> = ({ action, href, title, target }) => (
  <StyledLink target={target || '_self'} href={href} title={title || action}>
    {action}
  </StyledLink>
)

export default LinkButton
